import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './App.css';
import Error from './Error';
import Form from './components/Form';
import Reservations from './components/ReservationList';
import NANA from './nn.png';
import client from './api/client';

function App() {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [name, setName] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	console.log({ startDate, endDate });
	const [reservations, setReservations] = useState([]);

	useEffect(() => {
		getReservations();
	}, []);

	// clear error after 5 seconds
	useEffect(() => {
		const timeout = setTimeout(() => {
			setError('');
		}, 3500);
		return () => clearTimeout(timeout);
	}, [error]);

	const getReservations = async () => {
		try {
			setLoading(true);
			const response = await client.get('/reservations');
			setReservations(response.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const isDateReserved = (date) => {
		const formattedDate = date.toISOString().split('T')[0];
		return reservations.some((reservation) => {
			const reservedStartDate = new Date(reservation.startDate).toISOString().split('T')[0];
			const reservedEndDate = new Date(reservation.endDate).toISOString().split('T')[0];
			return formattedDate >= reservedStartDate && formattedDate < reservedEndDate;
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!name) {
			setError('Please enter a name.');
			return;
		} else if (!startDate) {
			setError('Please enter a start date.');
			return;
		} else if (!endDate) {
			setError('Please enter an end date.');
			return;
		} else
			try {
				// Ensure dates are in correct format before sending to server
				const formattedStartDate = startDate.toISOString();
				const formattedEndDate = endDate.toISOString();

				// Send the data to the Express server
				await client.post('/reservation', {
					name,
					startDate: formattedStartDate,
					endDate: formattedEndDate,
				});

				// Clear the form fields
				setName('');
				setStartDate(null);
				setEndDate(null);

				alert('Date range saved successfully!');
				window.location.reload();
			} catch (error) {
				console.error(error);
				setError(error.response ? error.response.data.message : error.message);
			}
	};

	return (
		<div className='bg-gray-900'>
			<form onSubmit={handleSubmit}>
				<Form
					name={name}
					setName={setName}
				/>
				{error && (
					<Error
						message={error}
						show={false}
					/>
				)}
			</form>
			<div className=''>
				<div
					className='sm:flex sm:justify-around flex-col min-h-[500px]'
					style={{
						backgroundImage: `url(${NANA})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						objectFit: 'cover',
						backgroundRepeat: 'no-repeat',
					}}
				>
					<div className='flex align-center '>
						{/* calendar container */}
						<div className='block sm:inline sm:order-3 bg-white justify-normal mx-4 p-5 rounded shadow '>
							<Calendar
								value={[startDate, endDate]}
								selectRange
								views={['month']}
								onChange={(value) => {
									setStartDate(value[0]);
									setEndDate(value[1]);
								}}
								tileDisabled={({ date }) => isDateReserved(date)}
							/>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Reservations reservations={reservations} />
			</div>
		</div>
	);
}

export default App;
