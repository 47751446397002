import formatDate from '../formatDate';
import ReservationRow from './ReservationRow';

export default function ReservationList({ reservations }) {
	return (
		<div className='bg-gray-900'>
			<div className='mt-12 font-semibold mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
				<div className='sm:flex sm:items-center'>
					<div className='sm:flex-auto'>
						<h1 className='text-7xl  font-semibold leading-6 text-gray-50 '>Reservations</h1>
					</div>
				</div>
			</div>
			<div className='mt-8 flow-root overflow-hidden'>
				<div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
					<table className='w-full text-left'>
						<thead className='bg-gray-900 text-gray-50'>
							<tr>
								<th
									scope='col'
									className='relative isolate py-3.5 pr-3 font-semibold text-gray-50 text-center text-xl'
								>
									Name
									<div className='absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200' />
									<div className='absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200' />
								</th>

								<th
									scope='col'
									className='px-3 py-3.5 font-semibold text-gray-50 text-left text-xl'
								>
									Start Date
								</th>
								<th
									scope='col'
									className='px-3 py-3.5 font-semibold text-gray-50 text-left text-xl'
								>
									End Date
								</th>
							</tr>
						</thead>
						<tbody>
							{reservations.map((r, i) => {
								console.log({ r });
								if (r.endDate < new Date().toISOString()) return null;
								return (
									<ReservationRow
										key={i}
										i={i}
										startDate={r.startDate}
										endDate={r.endDate}
										name={r.name}
									/>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
