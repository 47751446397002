import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useEffect } from 'react';

export default function Error({ message = '', show = false }) {
	useEffect(() => {
		show = true;
		setTimeout(() => {
			show = false;
		}, 5000);
	});
	return (
		<div className='rounded-md bg-rose-50 p-4'>
			<div className='flex'>
				<div className='flex-shrink-0'>
					<ExclamationTriangleIcon
						className='h-5 w-5 text-rose-400'
						aria-hidden='true'
					/>
				</div>
				<div className='ml-3'>
					<h3 className='text-sm font-medium text-rose-800'>
						An Error Occurred
					</h3>
					<div className='mt-2 text-sm text-rose-700'>
						<p>{message}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
