export default function Form({ name, setName }) {
	return (
		<div className='bg-gray-900  py-3 sm:py-10 lg:py-10'>
			<div className='mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8'>
				<div className='max-w-xl text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl lg:col-span-7'>
					<h2 className=''>Nana's Hotel</h2>{' '}
					<p className=' font-thin inline sm:block lg:inline xl:block'>Life's a beach, ain't it?</p>
				</div>
				<div className='w-full max-w-lg lg:col-span-5 lg:pt-2'>
					<div className='flex gap-x-4'>
						<label
							htmlFor='name'
							className='sr-only'
						>
							Name
						</label>
						<input
							id='name'
							name='name'
							onChange={(e) => setName(e.target.value)}
							type='text'
							autoComplete='name'
							required
							className='min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 bg-gray-800 text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
							placeholder='Enter your name'
						/>
						<button
							type='submit'
							className='flex-none rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
						>
							Save
						</button>
					</div>
					<p className='mt-4 text-sm leading-6 text-gray-50'>
						1. Enter your name. 2. Click a start date. 3. Click an end date. 4. Press save.
					</p>
				</div>
			</div>
		</div>
	);
}
