import React, { useEffect, useState } from 'react';
import formatDate from '../formatDate';
export default function ReservationRow({ i, name, startDate, endDate }) {
	function isCurrentDateInRange(startDate, endDate) {
		const currentDate = new Date();
		const start = new Date(startDate);
		const end = new Date(endDate);

		return currentDate >= start && currentDate <= end;
	}

	const [isInRange, setIsInRange] = useState(false);

	useEffect(() => {
		const current = isCurrentDateInRange(startDate, endDate);
		setIsInRange(current);
	}, []);

	if (isInRange) {
		console.log('Current date is within the range.');
	} else {
		console.log('Current date is not within the range.');
	}

	return (
		<tr
			key={i}
			className={isInRange ? 'bg-green-600 w-full' : 'w-full bg-gray-900'}
		>
			<td className='relative py-4 pr-3 text-sm font-medium text-gray-50 text-center'>
				{name}
				<div className='absolute bottom-0 right-full h-px w-screen bg-gray-100' />
				<div className='absolute bottom-0 left-0 h-px w-screen bg-gray-100' />
			</td>
			<td className='px-3 py-4 text-sm text-gray-500'>
				{formatDate(startDate)}
			</td>
			<td className='px-3 py-4 text-sm text-gray-500'>{formatDate(endDate)}</td>
		</tr>
	);
}
